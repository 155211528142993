<template>
    <section class="main-container">
        <SectionTitleComponent
            subheading="Tagline"
            title="Contact Us"
            :isLeftBound="true"
        />
        <section class="contact-form-details">
            <form class="details-form" @submit.prevent="submitForm">
                <div class="row">
                    <div class="form-group">
                        <label for="firstName" class="visually-hidden"
                            >First Name</label
                        >
                        <input
                            type="text"
                            id="firstName"
                            placeholder="First Name"
                            required
                            aria-label="First Name"
                            class="form-control"
                            v-model="form.firstName"
                        />
                    </div>
                    <div class="form-group">
                        <label for="lastName" class="visually-hidden"
                            >Last Name</label
                        >
                        <input
                            type="text"
                            id="lastName"
                            placeholder="Last Name"
                            required
                            aria-label="Last"
                            class="form-control"
                            v-model="form.lastName"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="form-group">
                        <label for="email" class="visually-hidden">Email</label>
                        <input
                            type="email"
                            id="email"
                            placeholder="Email"
                            required
                            aria-label="Email"
                            class="form-control"
                            v-model="form.email"
                        />
                    </div>
                    <div class="form-group">
                        <label for="phoneNumber" class="visually-hidden"
                            >Phone Number</label
                        >
                        <input
                            type="tel"
                            id="phoneNumber"
                            placeholder="Phone Number"
                            aria-label="Phone Number"
                            class="form-control"
                            v-model="form.phoneNumber"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label for="topic" class="visually-hidden"
                        >Choose a Topic</label
                    >
                    <select id="topic" class="form-select" v-model="form.topic">
                        <option disabled value="">Select one...</option>
                        <option v-for="topic in topics" :key="topic">
                            {{ topic }}
                        </option>
                    </select>
                </div>
                <section v-if="legendTitle" class="fieldset-container">
                    <fieldset>
                        <legend>{{ legendTitle }}</legend>
                        <div class="checkbox-group">
                            <div
                                class="checkbox"
                                v-for="(label, key) in topicOptions"
                                :key="key"
                            >
                                <input
                                    type="checkbox"
                                    :id="key"
                                    v-model="form.selectedOptions[key]"
                                />
                                <label :for="key">{{ label }}</label>
                            </div>
                        </div>
                    </fieldset>
                </section>
                <div class="form-group">
                    <label for="message" class="visually-hidden">Message</label>
                    <textarea
                        id="message"
                        placeholder="Type your message..."
                        aria-label="Message"
                        class="form-control"
                        rows="4"
                        v-model="form.message"
                    ></textarea>
                </div>
                <div class="form-group" style="display: none">
                    <input
                        type="text"
                        id="honeypot"
                        v-model="form.address2"
                        aria-hidden="true"
                        tabindex="-1"
                        autocomplete="off"
                    />
                </div>

                <div class="checkbox">
                    <input
                        type="checkbox"
                        id="consent"
                        v-model="form.consent"
                        required
                    />
                    <label for="consent"
                        >I agree to the
                        <a target="_blank" href="/terms" class="link"
                            >terms and conditions</a
                        ></label
                    >
                </div>
                <!-- <button type="submit" class="submit-btn">Submit</button> -->
                <ButtonComponent
                    type="submit"
                    label="Submit"
                    :isPrimary="true"
                />
            </form>
        </section>
    </section>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent.vue'
import SectionTitleComponent from '@/components/SectionTitleComponent.vue'

export default {
    name: 'ContactComponent',
    components: {
        ButtonComponent,
        SectionTitleComponent
    },
    props: {
        topics: {
            type: Array,
            default: () => [
                'General Inquiry',
                'Customer Support',
                'Technical Support',
                'Partnership',
                'Other'
            ]
        }
    },
    data() {
        return {
            form: {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                topic: '',
                message: '',
                consent: false,
                address2: '', // A hidden field to trick bots
                selectedOptions: {}
            }
        }
    },
    methods: {
        async submitForm() {
            try {
                if (this.form.address2) {
                    throw new Error("⚠️You're a bot!")
                }
                const response = await fetch(
                    'http://localhost:3000/api/send-email',
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(this.form)
                    }
                )
                console.log(this.form)

                if (!response.ok) {
                    throw new Error('❌Form submission failed!', response)
                }
                console.log('✅Form submitted successfully!')
            } catch (e) {
                console.error(e)
            }
            console.log(this.form)
        }
    },
    computed: {
        // Update the legend title dynamically
        legendTitle() {
            return this.form.topic || null
        },

        // Dynamically update the checkbox options based on the selected topic
        topicOptions() {
            switch (this.form.topic) {
                case 'General Inquiry':
                    return {
                        moreServices:
                            "I'd like to know more about your services",
                        pricing: "I'm interested in pricing information",
                        consultation: "I'd like to schedule a consultation",
                        other: 'Other (Please specify)'
                    }
                case 'Customer Support':
                    return {
                        accountIssues: 'Account issues',
                        billing: 'Billing or payment questions',
                        subscription: 'Subscription management',
                        serviceIssue: 'Service feedback or issue'
                    }
                case 'Technical Support':
                    return {
                        troubleshooting: 'Troubleshooting a product feature',
                        reportingBug: 'Reporting a bug or error',
                        guideRequest: 'Requesting a guide or tutorial',
                        setupHelp: 'Assistance with setup or configuration'
                    }
                case 'Partnership':
                    return {
                        partnershipOpportunity:
                            "I'm interested in a partnership opportunity",
                        collaboration: "I'd like to collaborate on a project",
                        companyCollab:
                            'I represent a company looking to collaborate'
                    }
                case 'Other':
                    return {
                        notSure: "I'm not sure which category fits my inquiry",
                        uniqueRequest: 'I have a unique request',
                        directContact: "I'd like to speak with someone directly"
                    }
                default:
                    return {}
            }
        }
    }
}
</script>

<style scoped>
.main-container {
    flex-direction: column;
    gap: 3rem;
}
.contact-form-header {
    max-width: 48rem;
}

.contact-form-header h1 {
    text-align: center;
    font-weight: 600;
    font-size: 48px;
    line-height: 120%;
}

.contact-form-header p {
    margin-top: 24px;
    font-size: 18px;
    line-height: 150%;
    color: var(--primary-color);
    max-width: 100%;
}

.details-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    max-width: 48rem;
    width: 100%;
}

.form-group,
.consent {
    display: flex;
    flex-direction: column;
    align-self: stretch;
}

.form-group {
    flex: 1;
}

.form-control,
.form-select,
textarea {
    padding: 12px;
    border: 1px solid var(--primary-color);
    background-color: var(--background-color);
    color: var(--primary-color);
    margin-top: 0.75rem;
}

legend {
    margin-bottom: 0.75rem;
}

fieldset {
    border: none;
}

.checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.checkbox input {
    margin-right: 8px;
}

.row {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    width: 100%;
}

.contact-form-details {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 48rem;
    max-width: 100%;
}

@media (max-width: 768px) {
    .checkbox-group {
        flex-basis: 100%; /* Full width on smaller screens */
        max-width: 100%; /* Full width on smaller screens */
    }
    .row {
        flex-direction: column;
    }
}

@media (max-width: 991px) {
    .form-group,
    .checkbox-group,
    .consent {
        max-width: 100%;
    }
}
</style>
